import React , {useEffect, useState} from 'react'
import { ManageContentWrapper } from './ManageContent.style'
import { Select, Table } from 'antd'
import GenricModal from 'components/comman/modalComponents/modal/modal';
import SecuredServices from 'api/securedServices';

export default function ManageContent() {
  const columns = [
    {
      title: 'Question Name',
      key: 'q_name',
      render: (text, record) => {
        return (
          <span>{record.q_name} {record.auto_generated && parseInt(record.auto_generated)!=0?`(${record.auto_generated})`:""}</span>
        )}
    },
    {
      title: 'Topic Name',
      dataIndex: 'topic_name',
      key: 'topic_name',
    },
    {
      title: 'Concept Name',
      dataIndex: 'concept_name',
      key: 'concept_name',

    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
     
    },
    {
        title:"Q type",
        dataIndex:"question_type",
        key:"question_type",
    },
    {
      title: 'Grade',
      dataIndex: 'grade_id',
      key: 'grade_id',

    },
    {
        title:"Comment",
        dataIndex:"comment",
        key:"comment"
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div>
          <button className='showDetailsBtn' onClick={() =>{
            setSelectedData(record);
            setQuestionDetailsModal(true)}}>Show Details</button>
        </div>
      ),
    }
  ];

  const [data,setData] = useState([]);

  

  const [filteredData, setFilteredData] = useState(data);
  const [selectedgrades,setSelectedGrades]= useState([-1]);
  const [selectedtopics,setSelectedTopics] = useState([-1]);
  const [selectedConcepts,setSelectedConcepts] = useState([-1]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData,setSelectedData] = useState({});
  const [questionDetailsModal,setQuestionDetailsModal] = useState(false);
  const [optionsData,setOptionsData] = useState({
    topics:[],
    concepts:[],
    grades:[],
  });



  useEffect(() => {
    let topics = [];
    let concepts = [];
    let grades = [];
    data.forEach((item) => {
      if (!topics.some((topic) => topic.topic_id === item.topic_id)) {
        console.log(item,topics,topics.includes(item.topic_id));
        topics.push({
          topic_id: item.topic_id,
          topic_name: item.topic_name,
        });
      }

      if (!concepts.some(topic=>topic.concept_id===item.concept_id)) {
        concepts.push({
          concept_id: item.concept_id,
          concept_name: item.concept_name,
        });
      }
      
      if (!grades.includes(item.grade_id)) {
        grades.push(item.grade_id);
      }
    });
    setOptionsData({
      topics: topics,
      concepts: concepts,
      grades: grades,
    });
  }, [data]);

  useEffect(() => {
    Promise.all([
      
    ])
    SecuredServices.getAllPendingCommits().then((res) => {
      console.log(res);
      if(res.data.status==1){
        res.data.data.forEach((item,index)=>{
          item.key = index+1;
        })
        console.log(res.data.data);

        SecuredServices.getAllMcqPendingCommits().then((res1) => {
          console.log(res1);
          if(res1.data.status==1){
            res1.data.data.forEach((item,index)=>{
              item.key = index+1;
            })
            setData([...res.data.data,...res1.data.data]);
        }
      })
     
      }
    }).catch((err) => {
      console.log(err);
    });

  }, []);





  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setFilteredData(data);
    let filtData = data;
    if(selectedgrades.length>0){
      filtData = filtData.filter((item)=>{
        if(selectedgrades.includes(-1)){
          return true;
        }
        else{
          return selectedgrades.includes(item.grade_id);
        }
      });
    }
   
    if(selectedtopics.length>0){
      filtData = filtData.filter((item)=>{
        if(selectedtopics.includes(-1)){
          return true;
        }
        else{
          return selectedtopics.includes(item.topic_id);
        }
      });
    }
    

    if(selectedConcepts.length>0){
      filtData = filtData.filter((item)=>{
        if(selectedConcepts.includes(-1)){
          return true;
        }
        else{
          return selectedConcepts.includes(item.concept_id);
        }
      })
    }
    
    setFilteredData(filtData);
  }, [selectedConcepts,selectedgrades,selectedtopics,data]);

  

  return (
    <ManageContentWrapper>
         <div className='flex space-between align-center'>
            <h4 className=''>Manage Content</h4>
        </div>
        <div className='filters'>   
        <div className="formElement">
                <label className='label'>Grades:</label>

                <Select 
                mode='multiple'
                value={selectedgrades}
                style={{width:"100%"}}
                onChange={(value)=>{
                  if(!selectedgrades.some(value=>value===-1) && value.some(value=>value===-1)){
                    setSelectedGrades([-1]);
                  }
                  else if(value.length>1 && value.find(value=>value===-1)!=undefined){
                    //remove value -1 from the value
                    setSelectedGrades(value.filter(value=>value!==-1));
                  }
                  else if(value.length== optionsData.grades.length){
                    setSelectedGrades([-1]);
                  }
                  else{
                    setSelectedGrades(value);
                  }
                }}
                >
                  <Select.Option value={-1}>ALL</Select.Option>
                  {
                    optionsData.grades.map((value,index)=>{
                      return <Select.Option key={index} value={value}>{value}</Select.Option>
                    })
                  }
                    {/* <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option> */}
                </Select>
        </div>
        <div className="formElement">
                <label className='label'>Topics:</label>
                <Select 
                mode='multiple'
                value={selectedtopics}
                style={{width:"100%"}}
                onChange={(value)=>{
                 console.log(value);
                 if(!selectedtopics.some(value=>value===-1) && value.some(value=>value===-1)){
                  setSelectedTopics([-1]);
                }
                else if(value.length>1 && value.find(value=>value===-1)!=undefined){
                    //remove value -1 from the value
                    setSelectedTopics(value.filter(value=>value!==-1))
                }
                else if(value.length== optionsData.topics.length){
                    setSelectedTopics([-1])
                }
                else{

                  setSelectedTopics(value);
                }
                }}
                >
                  <Select.Option value={-1}>ALL</Select.Option>
                  {
                    optionsData.topics.map((value,index)=>{
                      return <Select.Option key={index} value={value.topic_id}>{value.topic_name}</Select.Option>
                    })
                  }
                    {/* <Select.Option value={1}>Admin</Select.Option>
                    <Select.Option value={2}>Uploader</Select.Option>
                    <Select.Option value={3}>Programer</Select.Option>
                    <Select.Option value={4}>Verifier</Select.Option> */}
                </Select>
            </div>
            <div className="formElement">
                <label className='label'>Concepts:</label>
                <Select 
                mode='multiple'
                value={selectedConcepts}
                style={{width:"100%"}}
                onChange={(value)=>{
                  console.log(value);
                  if(selectedConcepts.length==1 && value.length==0){
                    setSelectedConcepts([-1]);
                  }
                  if(!selectedConcepts.some(value=>value===-1) && value.some(value=>value===-1)){
                    setSelectedConcepts([-1]);
                  }
                  else if(value.length>1 && value.find(value=>value===-1)!=undefined){
                      //remove value -1 from the value
                      setSelectedConcepts(value.filter(value=>value!==-1))
                  }
                  
                  else if(value.length== optionsData.concepts.length){
                      setSelectedConcepts([-1])
                  }
                  else{

                    setSelectedConcepts(value);
                  }
                }}
                >
                  <Select.Option value={-1}>ALL</Select.Option>
                  {
                    optionsData.concepts.map((value,index)=>{
                      return <Select.Option key={index} value={value.concept_id}>{value.concept_name}</Select.Option>
                    })
                  }
                </Select>
            </div>

           
        </div>
        <div>
            <Table columns={columns} dataSource={filteredData} rowSelection={rowSelection}/>
        </div>

         <div className='totalRowsSelected'>Total Rows selected : {selectedRowKeys?.length}</div>
        <div className='buttonsSections'>
                <button  className='buttonClass commit'>Commit</button>
              
        </div>

        <GenricModal 
            closable={true}
            width={900}
            title={"Admin Question Details"}
            data = {selectedData}
            isOpen={questionDetailsModal}
            toggle={()=>{
                setQuestionDetailsModal(false);
            }}
            
        />
      
    </ManageContentWrapper>
  )
}
