export const RouteDefinitions  = {
    ROUTE_LOGIN: "/login",
    ROUTE_UPLOAD: "/content/upload",
    ROUTE_CREATE: "/content/create",
    ROUTE_VERIFY: "/content/verify",
    ROUTE_ADMIN: "/content/admin",
    ROUTE_SUBMIT_EXACT_CONTENT:"/content/submit-exact",
    ROUTE_SUBMIT_VERIFICATION:"/content/verify/submit",
    ROUTE_ADMIN_MANAGE_USERS:"/content/admin/manage-users",
    ROUTE_ADMIN_MANAGE_CONTENT:"/content/admin/manage-content",
    ROUTE_SUBMIT_MISSION_PROGRAM:"/content/submit-mission-program",
    ROUTE_SUBMIT_MISSION_VERIFICATION:"/content/submit-mission-verification",
    ROUTE_SUBMIT_MCQ_PROGRAM: "/content/create/submit-mcq-program",
    ROUTE_ADMIN_MANAGE_CONCEPTS: "/content/admin/manage-concepts",
    ROUTE_TEST_MCQ_INSTANCE:"/content/test-mcq-instance",
    ROUTE_VERIFY_MCQ:"/content/verify/mcq",
    ROUTE_EARNINGS:"/earnings",
}