import React, { useEffect, useState } from 'react'
import { ShowQuestionDetailsWrapper } from './ShowQuestionDetails.style'
import QuestionDetails from 'components/comman/modalComponents/questionDetails/QuestionDetails'
import { getDate } from 'helper/utils'
import JsonFormatter from 'react-json-formatter';
import { saveAs } from 'file-saver';
import { IqLink } from 'helper/constants';
import SecuredServices from 'api/securedServices';
import { Button, Input, message,Select } from 'antd';
import GenricModal from 'components/comman/modalComponents/modal/modal';
import { useNavigate } from 'react-router-dom';

export default function ShowQuestionDetails({data,onCancel}) {

  const [concepts,setConcepts] = useState([]);
  const [selectedConcept,setSelectedConcept] = useState({concept_id:-1,concept_name:""});
  const [addNewConceptModal,setAddNewConceptModal] = useState(false);
  const [hardness,setHardness] = useState();
  const [testMcqModal,setTestMcqModal] = useState(false);
  const [mcqJson,setMcqJson]  = useState([]);

  const navigate = useNavigate();



  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
  }


  useEffect(()=>{
    SecuredServices.getConcepts(data.topic_id).then((res)=>{
        if(res.data.status==1){
            setConcepts(res.data.data.data);
            console.log(res.data.data.data);
        }
    })
    setHardness(data.hardness);

},[data.topic_id])

useEffect(()=>{
  if(data.type=="Mcq"){
  let mcqJs = [];
    data?.programDetails.forEach((program)=>{
      let d = {
        mcq_json: program.mcq_json,
      }
      mcqJs.push(d);
  })
  setMcqJson(mcqJs);
}
},[data])

useEffect(()=>{
  setSelectedConcept({concept_id:data.concept_id,concept_name:data.concept_name});
},[data.concept_id,data.concept_name])

  const handleDownLoadQATex = (attachments)=>{
    console.log(attachments);
    let link =attachments.find(att=>att.attachment_type=="Tex file")?.attachment_link;
    window.location.href=link;
  }

  const handleClickDownloadResources = (data,qName,type)=>{
    console.log(data,type);
    let text;
    if(type=="Mission"){
      text = {
        program:data.program,
        rubrics:JSON.parse(data.rubrics)
      }
    }
    else if(type=="Mcq"){
        console.log(type);
        text = {};
        data?.forEach((d,index)=>{
          text[`question ${index+1}`] = d?.mcq_json;
        })
        console.log(text);
    }
    else{
      text= {
        q_tex:data.q_tex,
        a_tex:data.a_tex,
        iq_annotation:data.iq_annotation
      };
    }
    

    saveAs(new Blob([JSON.stringify(text, null, 2)], { type: 'text/plain;charset=utf-8' }), `${qName}_resources.json`);

  }

  const handleClickPracticeLink = (type)=>{
    console.log(data);
    if(type=="Mission"){
      window.open(data.programDetails.attachments[0].pdf_link,'_blank','noopener,noreferrer');
    }
    else if(type=="Mcq"){
      setTestMcqModal(true);
    }
    else{
      let link = `${IqLink}ip-verify/${data.token}/${data.topic_id}/${data.q_id}`;
      window.open(link,'_blank','noopener,noreferrer');
    }
   
  }

  const handleClickCommit = ()=>{
    console.log(data.type);
   let Qdata;
    if(data.type=='Mcq'){
      Qdata = {
        q_id:"none",
        q_name:data.q_name,
        type:"mcq",
        concept:"none"
      }
    }
    else{
       Qdata = {
        q_id:data.q_id,
        concept:selectedConcept,
        q_name:data.q_name,
        type:data.type=="Mission"?"mission":data.type=="mcq"?"mcq":"iq",
        hardness:data.type=="Mission"?hardness:null,
      }
    }
    console.log(Qdata);
     

      if(Qdata.concept.concept_id==-1 && data.type!="Mcq"){
        message.error("Please select a concept");
        return;
      }
      if(Qdata.type=="mission" && Qdata.hardness==null ){
        message.error("Please select question difficulty.");
        return;
      }
      console.log(Qdata);
      SecuredServices.commitQuestion(Qdata).then((res)=>{
        console.log(res.data);
        if(res.data.status==1){
          message.success(res.data.message);
          //reload the page
          window.location.reload();
        }
        else{
          message.error(res.data.message);
        }
      }).catch((err)=>{
        console.log(err);
      })
    }


    const handleClickRevert = ()=>{
      let Qdata;
      console.log(data.type,data.q_name);
      if(data.type=="Mcq"){
        Qdata = {
          q_id:data.q_name,
          type: "mcq",
        }
      }
      else{
        Qdata = {
          q_id:data.q_id,
          type:data.type,
        }
      }
     
      console.log(Qdata);
      SecuredServices.revertQuestionToVerifier(Qdata).then((res)=>{
        console.log(res.data);
        if(res.data.status==1){
          message.success(res.data.message);
          //reload the page
          window.location.reload();
        }
        else{
          message.error(res.data.message);
        }
      }).catch((err)=>{
        console.log(err);
      })
    }

    const handleClickDiscard = ()=>{
      let Qdata = {
        q_id:data.q_id
      }
      console.log(Qdata);
      SecuredServices.discardQuestion(Qdata).then((res)=>{
        console.log(res.data);
        if(res.data.status==1){
          message.success(res.data.message);
          //reload the page
          window.location.reload();
        }
        else{
          message.error(res.data.message);
        }
      }).catch((err)=>{
        console.log(err);
      })
    }

    const handleChangeConcept = (value)=>{
      if(value==-1){
          setSelectedConcept({
              concept_id: -1,
              name:""
          });
      }
      concepts.forEach((concept)=>{
          if(concept.concept_id==value){
              setSelectedConcept(concept);
          }
      })

      console.log(selectedConcept);
  }

  return (
    <ShowQuestionDetailsWrapper>
        <div className='questionDetails'>
            <div className='row'>
                <div className='col'><b>Q-Name :</b> {data?.q_name}</div>
                <div className='col'><b>Q-Type :</b> {data?.question_type}</div>
            </div>
            <div className='row'>
                <div className="col"> <b>Topic :</b>{data?.topic_name}</div>
                <div className="col"><b>Concept :</b> {data?.concept_name}</div>
            </div>

            <div className='row'>
                <div className="col"><b>Grade :</b> {data?.grade_id}</div>
                <div className="col"><b>Start Date :</b> 09/03/2024</div>
            </div>
        </div>
        <div className='uploadsSection'>
          {/* we need to show the deta */}
          <p className='uploads'>Upload Section</p>
          <div>
        
            <div className="row">
              <div className="col"><b>Uploader :</b> {data?.uploadDetails?.name}</div>
              <div className="col"><b>Username:</b> {data?.uploadDetails?.username}</div>
            </div>

            <div className="row">
              <div className="col"><b>Upload Date :</b> {getDate(data?.uploadDetails?.createdAt)}</div>
              <div className="col"><b>Resubmit:</b> No</div>
            </div>
            <div>
                    <p>Uploader Comment</p>
                    <div className='uploader-comment'>
                        <p>{data?.uploadDetails?.uploader_comment}</p>
                    </div>
                </div>

            <div className='topicAndConcept'>
                        <p>Topic: {data?.topic_name}</p>
                       {data?.type!="Mcq" && <div>
                <label htmlFor="" className='label'>Concept:</label>
                <Select defaultValue={-1} style={{width:"100%"}} value={selectedConcept.concept_id} onChange={(value)=>handleChangeConcept(value)} >
                <Select.Option value={-1} key={-1}>Select Concept</Select.Option>
                       {
                        concepts?.map((concept)=>{
                               return <Select.Option value={concept.concept_id} key={concept.concept_id}>{concept.name}</Select.Option>
                           })
                       }
                    </Select>
            </div>}
                    </div>

                   {data?.type!="Mcq" && <div className="addNewConcept">
                      <Button type='primary' onClick={()=>{
                        navigate("/content/admin/manage-concepts");
                      }}>New Concept</Button>
                    </div>}
              { data.type=="Mission" && <div className='hardness'>
                      <label htmlFor="" className='label'>*Hardness:</label>
                      <Input placeholder='Input question difficulty level' value={hardness} onChange={(e)=>setHardness(e.target.value)} />
               </div>}

            <div className="images">
              {
                data?.uploadDetails?.attachments?.map((image,index) =>( image.attachment_type =="Mission Image" || image.attachment_type=="Mcq") &&<img key={index} src={image.attachment_link} alt="" />)
              }
              {/* <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt="" />
              <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt="" />
              <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt="" /> */}
            </div>

          {data?.type!="Mcq" &&  <div className='resources'>
                <button className='downloadResources' 
                onClick={()=>{handleDownLoadQATex(data?.uploadDetails.attachments)}}
                >Download QA Tex</button>
            </div>}
          </div>
        </div>

        <div className='programmerSection'>
            <p className='programs'>Programmer Section</p>
            <div>
                <div className="row">
                     <div className="col"><b>Programmer :</b> {data?.type!="Mcq" ?data?.programDetails?.name: data?.programDetails[0]?.name}</div>
                     <div className="col"><b>Username:</b> {data?.type!="Mcq" ?data?.programDetails?.username: data?.programDetails[0]?.username}</div>
                </div> 
                 <div className="row">
              <div className="col"><b>Submit Date :</b> {data?.type!="Mcq" ?getDate(data?.programDetails?.createdAt):getDate(data?.programDetails[0]?.createdAt)}</div>
              <div className="col"><b>Resubmit:</b> No</div>
            </div>

            </div>

            <div className='images'>
              {
                data?.programDetails?.attachments?.map((image,index) =>( image?.attachment_type =="Mission Image" || image.attachment_type=="MCQ Image") &&<img key={index} src={image.attachment_link} alt="" />)
              }
                {/* <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt="" />
                <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt="" /> */}
            </div>
            <div className='resources'>
                <button className='downloadResources' onClick={()=>{

                  handleClickDownloadResources(data.programDetails,data.q_name,data.type)
                   }}>Download Resources</button>
            </div>
        </div>
        <div className='verifierSection'>
            <p className='verifiers'>Verifier Section</p>
            <div>
                <div className="row">
                     <div className="col"><b>Verifier :</b> {data?.type!="Mcq"?data?.verificationDetails?.name:"NA"}</div>
                     <div className="col"><b>Username:</b> {data?.type!="Mcq" ?data?.verificationDetails?.username:"NA"}</div>
                </div> 
                 <div className="row">
                  <div className="col"><b>Submit Date :</b> {data?.type!="Mcq" ?getDate(data?.verificationDetails?.createdAt):"NA"}</div>
                  <div className="col"><b>Resubmit:</b> No</div>
                </div>
            </div>

          

           {data?.type!="Mcq" && <><div className='commentP'><p>Comment</p></div>
            <div className='commentSection'>
                    <JsonFormatter json={data?.comment} tabWith={4} jsonStyle={jsonStyle} />
                    
            </div>
            </>
            }

            <div className="link">
                <button className='practiceLink' onClick={()=>{handleClickPracticeLink(data.type)}}>Practice Link</button>
            </div>
        </div>

        <div className='buttonsSections'>
        <button  className='buttonClass commit' onClick={()=>{handleClickCommit()}}>Commit</button>
        {/* <button className='buttonClass rtp'>Revert to Programer</button>*/}
                <button className='buttonClass rtv' onClick={()=>{handleClickRevert()}}>{data?.type!="Mcq" ?"Revert to Verifier":"Revert to Programer"}</button> 
                <button className='buttonClass discard' onClick={()=>{handleClickDiscard()}}>Discard</button>
        </div>


        <GenricModal
        closable={true}
        width={900}
        data={mcqJson}
        title={"Test Mcq Questions"}
        isOpen={testMcqModal}
        toggle={() => setTestMcqModal(false)}
      />
     
    </ShowQuestionDetailsWrapper>



  )

}
