import axios from "axios";
import Header from "./headers";
import { Url } from "helper/constants";

export default class SecuredServices {
    static uploadQuestion = async(data) => {
        try {
            Header.setHeaders();
            const response = await axios.post(`${Url}/uploads/question`, data, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            console.log('File uploaded successfully', response.data);
            return response;
          } catch (error) {
            console.error('Error uploading file', error);
            throw error;
          }  
}
static getUploadedQuestions = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/uploads/getUploadedQuestions`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Uploaded Questions', error);
    throw error;
}
}

//uploader services ends





//programer services


static getAllPendingQuestions = async() => {
  try {
    Header.setHeaders();
    const response = await axios.get(`${Url}/programs/getAllPendingQuestions`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error getting Pending Questions', error);
    throw error;
}
}   

static getUserPendingQuestions = async() => {
  try {
    Header.setHeaders();
    const response = await axios.get(`${Url}/programs/getUserPendingQuestions`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error getting Pending Questions', error);
    throw error;
  }}


static postAssignQuestionToUser = async(data) => {
    try {
      Header.setHeaders();
      const response = await axios.post(`${Url}/programs/assignQuestionToUser`, data);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error Assigning Question to User', error);
      throw error;
  }
  }  


  static postDeAssignQuestionFromUser = async(data) => {
    try {
      Header.setHeaders();
      const response = await axios.post(`${Url}/programs/deAssignQuestionFromUser`, data);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error DeAssigning Question to User', error);
      throw error;
  }
  }


  static getTopics = async(grade) => {
    try {
      Header.setHeaders();
      const response = await axios.get(`${Url}/live/get-topics?grade=${grade}`);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error getting Topics', error);
      throw error;
  }
  }

  static getConcepts = (topic_id) => {
    try {
      Header.setHeaders();
      const response = axios.get(`${Url}/live/get-concepts?topic=${topic_id}`);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error getting Concepts', error);
      throw error;
  }
  }

  static getMcqConcepts = (topic_id) => {
    try {
      Header.setHeaders();
      const response = axios.get(`${Url}/live/get-mcq-concepts?topic=${topic_id}`);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error getting Concepts', error);
      throw error;
  }
  }

  static getAllConcepts = () => {
    try {
      Header.setHeaders();
      const response = axios.get(`${Url}/live/get-all-concepts`);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error getting Concepts', error);
      throw error;
  }
  }

  static getTopicStream = (topic_id) => {
    try {
      Header.setHeaders();
      const response = axios.get(`${Url}/live/get-topic-stream?topic_id=${topic_id}`);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error getting Concepts', error);
      throw error;
  }
  }
  

  static getTopicConcepts = (grade,topic_name,stream_id) => {
      try{
        Header.setHeaders();
        const response = axios.get(`${Url}/live/get-topic-concepts?grade=${grade}&topic_name=${topic_name}&stream_id=${stream_id}`);
        console.log('request success', response.data);
        return response;
      } catch(error){
        console.error('Error getting Concepts', error);
        throw error;
      }
  }

  static createNewConcept = (data)=>{
    try {
      Header.setHeaders();
      const response = axios.post(`${Url}/live/create-concept`,data);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error Creating New Concept', error);
      throw error;
    }
  }

  static updateTopicConcepts = (data)=>{
    try {
      Header.setHeaders();
      const response = axios.post(`${Url}/live/update-topic-concepts`,data);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error Updating Topic Concepts', error);
      throw error;
    }
  }


  static submitExactImagesAndGenerateId = async(data) => {
    try {
        Header.setHeaders();
        const response = await axios.post(`${Url}/programs/submitExactImagesAndGenerateIds`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('File uploaded successfully', response.data);
        return response;
      } catch (error) {
        console.error('Error uploading file', error);
        throw error;
      }  
}


  static submitExactFiles = (data)=>{
    try {
      Header.setHeaders();
      const response = axios.post(`${Url}/programs/submitExactTypeFiles`,data);
      console.log('request success', response.data);
      return response;
    } catch (error) {
      console.error('Error Submitting Exact Files', error);
      throw error;
  }
}

static getSubmittedExactImages = (q_name,creator_queue_id,isQid)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/getSubmittedExactImages?q_name=${q_name}&creator_queue_id=${creator_queue_id}&isQid=${isQid}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Submitted Exact Images', error);
    throw error;
}
}

static removeExactImage = (id,q_name,creator_queue_id,isQid)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/removeExactImage?id=${id}&q_name=${q_name}&creator_queue_id=${creator_queue_id}&isQid=${isQid}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Removing Exact Image', error);
    throw error;
    }
}



static getProgramerSubmittedQuestion = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/getUserSubmittedQuestions`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Programer Submitted Question', error);
    throw error;
}
}

static getInstanceDetailsByQid = (qId)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/getinstancedetailsbyqid?qid=${qId}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Instance Details By Qid', error);
    throw error;
    }
}


static submitMissionProgramAndRubrics = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/programs/submitmissionprogram`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Submitting Mission Program And Rubrics', error);
    throw error;
}
}
static getProgramDetailsByQName = (q_name)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/getProgramDetailsByQName?q_name=${q_name}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Program Details By Qname', error);
    throw error;
  }
}

static getMcqDetailsByQName = (q_name)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/programs/getMcqDetailsByQName?q_name=${q_name}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Mcq Details By Qname', error);
    throw error;
  }
}

static postSubmitMcqInstances = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/programs/submitMcqInstance`,data);
    console.log('request success', response.data);
    return response;
    
  } catch (error) {
    console.error('Error Submitting Mcq Instances', error);
    throw error;
  }
}

//programer services ends




//verifier services

static getAllPendingVerification = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/verifications/getAllPendingVerifications`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All Pending Verification', error);
    throw error;
}
}
static getAllUserPendingVerification = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/verifications/getAllUserPendingVerifications`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All User Pending Verification', error);
    throw error;  
  }
}

static getVerificationDetails = (isQname,id)=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/verifications/getVerificationDetails?${isQname?"q_name":"q_id"}=${id}`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting Verification Details', error);
    throw error; 
  }
}
static assignVerificationToUser = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/verifications/assignVerificationToUser`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Assigning Verification to User', error);
    throw error;
  }
}

static deAssignVerificationFromUser = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/verifications/deAssignVerification`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error DeAssigning Verification to User', error);
    throw error;
  }
}


static submitVerification = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/verifications/submitVerification`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Submitting Verification', error);
    throw error;
  }
}

static submitVerificationMission = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/verifications/submitVerificationMission`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Submitting Verification', error);
    throw error;
  }
}


static submitVerificationMcq = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/verifications/submitVerificationMcq`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Submitting Verification', error);
    throw error;
  }
}

static getAllSubmittedVerification = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/verifications/getAllSubmittedVerifications`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All Submitted Verification', error);
    throw error;
  }
}

//verifier services ends



//admin services
static getAllUsers = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/admin/getallusers`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All Users', error);
    throw error;
}

}

static updateUser = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.put(`${Url}/admin/edituser`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Updating User', error);
    throw error;
}
}

static changeUserPassword = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/admin/changepassword`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Changing Password', error);
    throw error;
}
}

static createNewUser = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/admin/createnewuser`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Creating New User', error);
    throw error;
  }
}

static getAllPendingCommits = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/admin/pendingquestionsforcommit`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All Pending Commits', error);
    throw error;
}
}

static getAllMcqPendingCommits = ()=>{
  try {
    Header.setHeaders();
    const response = axios.get(`${Url}/admin/getmcqpendingqustionsforcommit`);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Getting All Pending Commits', error);
    throw error;
}
}

static commitQuestion = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/admin/commitquestionsbyqid`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Committing Question', error);
    throw error;
}
}

static revertQuestionToVerifier = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/admin/revertquestionbacktoverifier`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Reverting Question to Verifier', error);
  }
}

static discardQuestion = (data)=>{
  try {
    Header.setHeaders();
    const response = axios.post(`${Url}/admin/discardquestion`,data);
    console.log('request success', response.data);
    return response;
  } catch (error) {
    console.error('Error Discarding Question', error);
    throw error;
}
}

//admin services ends


//earning services
static getUserEarnings = ()=>{
  try {
      Header.setHeaders();
      const response = axios.get(`${Url}/earnings/get-user-earnings`);
      console.log('request success', response.data);
      return response;
  } catch (error) {
      console.error('Error Getting User Earnings', error);
      throw error;
      
  }
}





}
 
