import styled from "styled-components";

export const EarningsWrapper = styled.div`
    .outerTableDiv{
        padding: 1% 3%;
        border: 1px solid #c5c5c5;
        border-radius: 5px;
    }
    .tableCus{
        border: 1px solid #c3c3c3;
    border-radius: 5px;
    /* padding: 2%; */
    margin-top: 3%;
    }
    .headings{
        display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
    /* justify-content: space-around; */
    font-weight: bold;
    color: #213861;
    border-bottom: 1px solid #bebaba;
    padding: 1.5% 2%;
    }
    .content{
        display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
    border-bottom: 1px solid #bababa;
    padding: 1.5% 2%;
    }
    .footer{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
    padding: 1.5% 2%;
    font-weight: bold;
    color: green;
    }
`;
