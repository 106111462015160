import React, { useEffect } from 'react'
import { QuestionDetailsWrapper } from './QuestionDetails.style'
import { useNavigate } from 'react-router-dom';
import ShowImages from '../modal/ShowImages/ShowImages';
import SecuredServices from 'api/securedServices';
import { saveAs } from 'file-saver';

export default function QuestionDetails({data,handleSubmitFilesClick}) {
  const Navigate = useNavigate();
  useEffect(()=>{
    console.log(data);
  },[])

  const handleDownloadResourcesClick  = ()=>{
    if(data.content_type=="mission" && data.type =="program"){
      SecuredServices.getInstanceDetailsByQid(data.q_id).then((res)=>{
        if(res.data.status ==1){
          console.log(res.data);
          let tex_data = {
              q_tex: res.data.data[0]?.q_tex,
              a_tex: res.data.data[0]?.a_tex,
          };

          //generate a local link to download this tex_data in a json file 
          saveAs(new Blob([JSON.stringify(tex_data, null, 2)], { type: 'text/plain;charset=utf-8' }), `${data.q_name}_resources.json`);
        }
       
        

      })
    }
    else if(data.content_type=="iq" && data.type=="exact"){
      let link =data.attachments.find(att=>att.attachment_type=="Tex file")?.attachment_link;
          console.log(link);
          window.location.href=link;
    }
    else if(data.content_type =="mcq" && data.type=="program"){
      let link = data.attachments.find(att=>att.attachment_type=="Tex file")?.attachment_link;
      console.log(link);
      window.location.href=link;
    }
  }

  return (

    <QuestionDetailsWrapper >
      <div className='uploaderComment'>
        Uploader Comment: 
        <p> {data.uploaderComment}</p>
        
      </div>
       <ShowImages attachments={data.attachments} />
      <div className='buttonsSection' >
        <div className='downloadButton bg-green' style={data.content_type=="mcq"?{display:"none"}:{}} onClick={()=>{
          handleDownloadResourcesClick();
          
        }}>
          <p>Download Resources</p>
        </div>
        <div className='SubmitFiles bg-blue' onClick={()=>handleSubmitFilesClick(data.q_name)}>
          {
            data.content_type=="mcq"?"Submit MCQ":"Submit Files"
          }
         
        </div>
      </div>

    </QuestionDetailsWrapper>
  )
}
