import React from 'react'
import { RouteDefinitions } from './routeDefinitions';
import { Route, Routes } from 'react-router-dom';
import DashboardScreen from '../../screens/dashboard/dashboardScreen';
import LoginScreen from '../../screens/login/loginScreen';
import ReactCropImage from 'components/comman/ReactCrop/ReactCropImage';
import VerifyMission from 'components/Verifier/VerifiyMission/VerifyMission';
import SubmitMcqProgram from 'components/Creator/SubmitMcqProgram/SubmitMcqInstance';
import TestMcqInstance from 'components/Creator/TestMcqInstance/TestMcqInstance';

export default function CustomRoutes() {
  return (
    <Routes>
      <Route path={RouteDefinitions.ROUTE_LOGIN} element={<LoginScreen />} />
      <Route path={RouteDefinitions.ROUTE_UPLOAD} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_CREATE} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_VERIFY} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_ADMIN} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_SUBMIT_EXACT_CONTENT} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_SUBMIT_VERIFICATION} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_ADMIN_MANAGE_USERS} element={<DashboardScreen />} />
      <Route path={RouteDefinitions.ROUTE_ADMIN_MANAGE_CONTENT} element={<DashboardScreen />} />
      <Route path = {RouteDefinitions.ROUTE_REACT_IMAGE_CROP} element={<DashboardScreen/>} />
      <Route path = {RouteDefinitions.ROUTE_SUBMIT_MISSION_PROGRAM} element={<DashboardScreen/>} />
      <Route path={RouteDefinitions.ROUTE_SUBMIT_MISSION_VERIFICATION} element={<VerifyMission/>} />
      <Route path={RouteDefinitions.ROUTE_SUBMIT_MCQ_PROGRAM} element={<DashboardScreen/>} />
      <Route path = {RouteDefinitions.ROUTE_ADMIN_MANAGE_CONCEPTS} element={<DashboardScreen/>} />
      <Route path = {RouteDefinitions.ROUTE_TEST_MCQ_INSTANCE} element={<TestMcqInstance/>} />
      <Route path = {RouteDefinitions.ROUTE_VERIFY_MCQ} element={<DashboardScreen/>} />
      <Route path = {RouteDefinitions.ROUTE_EARNINGS} element={<DashboardScreen/>} />
    </Routes>
  )
}
