import React,{useState,useEffect} from 'react'
import { DashboardScreenWrapper } from './dashboardScreen.style'
import { Route, Routes } from 'react-router-dom';
import DashboardLogo  from "assets/images/PractWorksLogoWebsite.png";
import { useSelector,useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/authSlice';
import MenuIcon from "assets/images/menuIcon.svg";
import { useMediaQuery } from 'react-responsive';
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";
// import { login } from '../../redux/auth/authSlice';
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    DiffOutlined,
    CheckCircleOutlined,
    ExportOutlined
  } from '@ant-design/icons';
  import { Breadcrumb, Layout, Menu, theme } from 'antd';
import UploadQuestion from 'components/Uploader/uploadSection/uploadSection';
import { useNavigate } from 'react-router-dom';

import CreateContent from 'components/Creator/createContent/CreateContent';
import { RouteDefinitions } from 'api/routes/routeDefinitions';
import SubmitExact from 'components/Creator/SubmitExact/SubmitExact';
import VerifyContent from 'components/Verifier/VerifyContent/VerifyContent';
import SubmitVerification from 'components/Verifier/SubmitVerification/SubmitVerification';
import AuthServices from 'api/authServices';
import { userroles } from '../../redux/auth/authSlice';
import AdminActions from 'components/AdminActions/AdminActions';
import ReactCropImage from 'components/comman/ReactCrop/ReactCropImage';
import SubmitMissionProgram from 'components/Creator/SubmitMissionProgram/SubmitMissionProgram';
import VerifyMission from 'components/Verifier/VerifiyMission/VerifyMission';
import SubmitMcqProgram from 'components/Creator/SubmitMcqProgram/SubmitMcqInstance';
import VerifyMcq from 'components/Verifier/VerifyMcq/VerifyMcq';
import Earnings from 'components/Earnings/Earnings';
  const { Header, Content, Footer, Sider } = Layout;
  function getItem(label, key, icon,route ,children) {
    return {
      key,
      icon,
      children,
      label,
      route
    };
  }
  const items = [
    getItem('Upload Question', '1', <UploadOutlined />,RouteDefinitions.ROUTE_UPLOAD),
    getItem('Create Content', '2', <DiffOutlined />,RouteDefinitions.ROUTE_CREATE),
    getItem('Verify Content', '3', <CheckCircleOutlined />,RouteDefinitions.ROUTE_VERIFY),
    getItem('Admin Actions', '4', <UserOutlined />,RouteDefinitions.ROUTE_ADMIN,[
      getItem('Manage Users', '41', <TeamOutlined />,RouteDefinitions.ROUTE_ADMIN_MANAGE_USERS),
      getItem('Manage Content', '42', <ExportOutlined /> ,RouteDefinitions.ROUTE_ADMIN_MANAGE_CONTENT),
      getItem('Manage Concepts', '43', <PieChartOutlined /> ,RouteDefinitions.ROUTE_ADMIN_MANAGE_CONCEPTS),
    ]),
    // getItem('Files', '9', <FileOutlined />),
  ];



 function DashboardScreen(props) {

    const location  = useLocation();
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [selectedSideBarItem, setSelectedSideBarItem] = useState('1');
    const [sideBarOptions,setSideBarOptions] = useState([]);
    const userData = useSelector(state=>state.user);
    const Navigate = useNavigate();

    const MobileL = useMediaQuery({
        query: "(max-width: 500px)",
    })
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  useEffect(()=>{
    // if(!userData.token){
    //     Navigate("/login");
    // }
    console.log(location.pathname);
    getItemsList();
  },[userroles])





  




  // const getItemsList=()=>{
  //   let filteredItems = items.filter((item,index)=>{
  //       console.log(userData.roles.find(role=>role.roleid===2));
  //       if(index==0 && userData.roles.find(role=>role.roleid===2)!=null){
  //           return true;
  //       }
  //       if(index==1 && userData.roles.find(role=>role.roleid===3)!=null){
  //           return true;
  //       }
  //       if(index==2 && userData.roles.find(role=>role.roleid===4)!=null){ 
  //           return true;
  //       }
  //       if(index==3 && userData.roles.find(role=>role.roleid===1)){
  //           return true;
  //       }
       
  //   })
  //   console.log(items);
  //   console.log(filteredItems);
  //   // setSelectedSideBarItem(filteredItems[0].key);

  //   setSideBarOptions(filteredItems);
  //   return filteredItems;
  // }

  const getItemsList = () => {
    let includedItems =1;
    let filteredItems = items.filter((item, index) => {
      // Check for specific roles and include the respective items
      if (index === 0 && userData.roles.find(role => role.roleid === 2) != null) {
        includedItems++;
        return true;
      }
      if (index === 1 && userData.roles.find(role => role.roleid === 3) != null) {
        includedItems++;
        return true;
      }
      if (index === 2 && userData.roles.find(role => role.roleid === 4) != null) {
        includedItems++;
        return true;
      }
      if (index === 3) {
        let childrenToInclude = [];
  
        // Check for role id 7, include the first child
        if (userData.roles.find(role => role.roleid === 8)) {
          item.children.key = childrenToInclude.length + 1;
          childrenToInclude.push({...item.children[0],key:`${includedItems}${childrenToInclude.length+1}`}); // 'Manage Content'

        }
  
        // Check for role id 8, include the second child
        if (userData.roles.find(role => role.roleid === 7)) {
          console.log(item.children[1]);
          item.children.key = childrenToInclude.length + 1;
          childrenToInclude.push({...item.children[1],key:`${includedItems}${childrenToInclude.length+1}`}); // 'Manage Content'
        }
  
        // Check for role id 9, include the third child
        if (userData.roles.find(role => role.roleid === 9)) {
          item.children.key = childrenToInclude.length + 1;
          childrenToInclude.push({...item.children[2],key:`${includedItems}${childrenToInclude.length+1}`}); 
          // 'Manage Content' // 'Manage Concepts'
        }
  
        // If any children need to be included, modify the item's children array
        if (childrenToInclude.length > 0) {
          item.children.key = childrenToInclude.length + 1;
          item.children = childrenToInclude;
          return true; // Include the item with the selected children
        }
      }
      return false; // Exclude the item if no conditions are met
    });

    setSideBarOptions(filteredItems);
    console.log(filteredItems);
    return filteredItems;
  };

  useEffect(()=>{

    AuthServices.getUserRoles().then((res)=>{
      if(res.data.status===0){
        console.log(res.data.message);
      }
      else{
        console.log(res.data.data);
        dispatch(userroles(res.data.data));
        console.log(res.data.data.find(role=>role.roleid===1));
        console.log(location.pathname,RouteDefinitions.ROUTE_VERIFY);
        if(location.pathname==RouteDefinitions.ROUTE_UPLOAD && res.data.data.find(role=>role.roleid==2) ==undefined){
            dispatch(logout());
            Navigate("/login");
        }
        else if(location.pathname==RouteDefinitions.ROUTE_CREATE && res.data.data.find(role=>role.roleid==3) ==undefined){
            dispatch(logout());
            Navigate("/login");
        }
        else if(location.pathname==RouteDefinitions.ROUTE_VERIFY && res.data.data.find(role=>role.roleid==4)==undefined){
            dispatch(logout());
            Navigate("/login");
        }
        else if(location.pathname==RouteDefinitions.ROUTE_ADMIN && res.data.data.find(role=>role.roleid==1)==undefined){
            dispatch(logout());
            Navigate("/login");
        }
      }
    })
  },[])



  return (
   <DashboardScreenWrapper>
    <Layout
   
      style={{
        backgroundColor:"white",
        minHeight: '100vh',
      }}
    >
        <div className='left'>
        <Sider 
        width={230}
         breakpoint="sm"
         collapsedWidth="0"
         onBreakpoint={(broken) => {
           console.log(broken);
         }}
         trigger={null}

        collapsed={collapsed} 

        onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical"><img src={DashboardLogo} width={"90%"} alt="" /> </div>
        <Menu style={{marginTop:"10%"}} 
        theme="light" 
        mode="inline" 
        defaultSelectedKeys={[location.pathname === RouteDefinitions.ROUTE_UPLOAD ? "1" : location.pathname===RouteDefinitions.ROUTE_CREATE ? "2" : location.pathname===RouteDefinitions.ROUTE_VERIFY ? "3" : location.pathname===RouteDefinitions.ROUTE_ADMIN ? "4" : ""]}
        items={sideBarOptions} 
        onClick={(value) => {
            console.log(value);
          setSelectedSideBarItem(value.key);
          if(value.key.length>1){

            console.log(sideBarOptions[value.key[0]-1]);
            Navigate(`${sideBarOptions[value.key[0]-1]?.children[value.key[1]-1]?.route}`);
          }
          else{
            Navigate(`${sideBarOptions.find(item=>item.key===value.key)?.route}`);

          }
          if(MobileL){
            setCollapsed(true);
          }
        }}
        />
      </Sider>
        </div>
     
      <Layout 

      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: 'flex',
            alignItems: 'right',
            height:"6vh"
          }}
        >
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%",margin:"0 2%" }}>
                <img src={MenuIcon} onClick={()=>setCollapsed(!collapsed)} width={30} alt="" />
                <div className='flex align-items-center space-between' style={{width:'130px'}}>
                  <p className='mr-2' onClick={()=>Navigate("/earnings")}>Earnings</p>
                <p 
                onClick={()=>{
                    dispatch(logout());
                    Navigate("/login");
                }}>Logout</p>
                </div>
               
            </div>
            </Header>
           
        <Content
          style={{
            margin: '0 16px',
            maxHeight:"88vh",
            overflowY:"scroll"
          }}
        >
            {
                location.pathname===RouteDefinitions.ROUTE_UPLOAD?<UploadQuestion/>:
                location.pathname===RouteDefinitions.ROUTE_CREATE? <CreateContent/>:
                location.pathname===RouteDefinitions.ROUTE_VERIFY?<VerifyContent/>:
                location.pathname===RouteDefinitions.ROUTE_SUBMIT_EXACT_CONTENT?<SubmitExact/>
                :location.pathname===RouteDefinitions.ROUTE_SUBMIT_VERIFICATION?<SubmitVerification />
                :location.pathname===RouteDefinitions.ROUTE_ADMIN_MANAGE_CONTENT?<AdminActions />:
                location.pathname===RouteDefinitions.ROUTE_ADMIN_MANAGE_USERS?<AdminActions />:
                location.pathname===RouteDefinitions.ROUTE_ADMIN_MANAGE_CONCEPTS?<AdminActions />:
                location.pathname ===RouteDefinitions.ROUTE_SUBMIT_MISSION_PROGRAM?<SubmitMissionProgram/>:
                location.pathname===RouteDefinitions.ROUTE_SUBMIT_MCQ_PROGRAM?<SubmitMcqProgram/>:
                location.pathname===RouteDefinitions.ROUTE_VERIFY_MCQ?<VerifyMcq/>:
                location.pathname===RouteDefinitions.ROUTE_EARNINGS?<Earnings/>:
                <div></div>
            }
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            marginBottom:"0%",
          }}
        >
          Practworks Mentors Pvt. Ltd. ©{new Date().getFullYear()} All rights reserved.
        </Footer>
      </Layout>
    </Layout>
   </DashboardScreenWrapper>
  )
}

export default DashboardScreen;