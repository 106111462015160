import React, {useState, useEffect } from 'react'
import { EarningsWrapper } from './Earnings.style'
import SecuredServices from 'api/securedServices'

export default function Earnings() {

    const [earnings, setEarnings] = useState([]);

    useEffect(()=>{
        SecuredServices.getUserEarnings().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                setEarnings(res.data.data);
            }
        })
    },[])
  return (
    <EarningsWrapper>
        <h2 className='text-center dark-blue'>Earnings</h2>
        <div className='outerTableDiv'>
            <h3 className='text-center' style={{color:"green",fontSize:"17px",fontWeight:"bold"}}>
                Total Earnings (Payment pending) : ₹{earnings.totalAmount}
            </h3>
            <div className='tableCus'>
                <div className='headings'>
                    <p>Type</p>
                    <p>Submissions (Payment pending)</p>
                    <p>Amount</p>
                </div>

                <div className='content'>
                    <p>IQ creations with image</p>
                    <p>{earnings?.iqCreation?.count_iq_with_image}</p>
                    <p>₹{earnings?.iqCreation?.amount_for_iq_with_image}</p>
                </div>
                <div className='content'>
                    <p>IQ creations without image</p>
                    <p>{earnings?.iqCreation?.count_iq_without_image}</p>
                    <p>₹{earnings?.iqCreation?.amount_for_iq_without_image}</p>
                </div>
                {/* <div className='content'>
                    <p>Program creations</p>
                    <p>{earnings?.programCreation?.count}</p>
                    <p>{earnings?.programCreation?.amount}</p>
                </div> */}
                <div className='content'>
                    <p>IQ verifications</p>
                    <p>{earnings?.iqVerification?.count}</p>
                    <p>₹{earnings?.iqVerification?.amount}</p>
                </div>
                {/* <div className='content'>
                    <p>Program verifications</p>
                    <p>{earnings?.missionVerification?.count}</p>
                    <p>₹{earnings?.missionVerification?.amount}</p>
                </div> */}
                <div className='footer'>
                    <p>Total</p>
                    <p>{earnings?.totalCount}</p>
                    <p>₹{earnings?.totalAmount}</p>
                </div>
            </div>
        </div>
    </EarningsWrapper>
  )
}
