import styled from "styled-components";


export const SubmitMcqProgramWrapper = styled.div`
.formParent{
    padding: 2%;
    margin-top: 4%;
    max-width: 80%;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding-top: 4%;
    min-height: 17rem;
}

.changeTopicButton{
    color: #0089ff;
    text-decoration: underline;
}
.selectConcept{
    margin: 2% 0%;
}
.label1{
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 3%;
}
.topicName{
    display: flex;
    justify-content: space-between;
}

.submitExactform{
    margin: 4% 0%;
}
.formElement{
    margin: 2% 0%;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 4%;
}

.saveAndTestButton{
    text-align: center;
}
.saveAndTestButton button{
    border: none;
    background: #213861;
    width: 55%;
    padding: 2%;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.buttonsSections{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding:0 5%;
    margin-top:10%;
}

.submitButton{
    width: 150px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
`;