import React, { useEffect, useState } from 'react'
import { McqTestingPageWrapper } from './McqTestingPage.style'
import { typesetPromiseFunction } from 'helper/utils';

export default function McqTestingPage({data}) {

    const [mcqData,setMcqData] = useState([]);
    useEffect(() => {
        console.log(data);
        try {
            let datatemp = data.map((item)=>{
                return JSON.parse(item.mcq_json);
            });
            setMcqData(datatemp);
            console.log(datatemp);
        } catch (error) {
            console.log(error);
            // throw error; 
        }
     
      }, [data])

      const [mathjaxConfig, setMathjaxConfig] = useState({
        tex: { packages: { "[+]": ["input"] },
        inlineMath: [["$", "$"], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],  
     },
     
        startup: {
            ready() {
                const Configuration =
                    window.MathJax._.input.tex.Configuration.Configuration;
                const CommandMap = window.MathJax._.input.tex.SymbolMap.CommandMap;
                const TEXCLASS = window.MathJax._.core.MmlTree.MmlNode.TEXCLASS;

                new CommandMap(
                    "input",
                    { input: "Input" },
                    {
                        Input(parser, name) {
                            const xml = parser.create("node", "XML");
                            const id = parser.GetBrackets(name, "");
                            const w = parser.GetBrackets(name, "3em");
                            const value = parser.GetArgument(name);
                            xml.setXML(
                                window.MathJax.startup.adaptor.node("input", {
                                    id: id,
                                    value: value,
                                    style: { width: w },
                                    xmlns: "http://www.w3.org/1999/xhtml",
                                }),
                                window.MathJax.startup.adaptor
                            );
                            xml.getSerializedXML = function () {
                                return this.adaptor.outerHTML(this.xml) + "</input>";
                            };
                            parser.Push(
                                parser.create(
                                    "node",
                                    "TeXAtom",
                                    [
                                        parser.create("node", "semantics", [
                                            parser.create("node", "annotation-xml", [xml], {
                                                encoding: "application/xhtml+xml",
                                            }),
                                        ]),
                                    ],
                                    { texClass: TEXCLASS.ORD }
                                )
                            );
                        },
                    }
                );
                Configuration.create("input", { handler: { macro: ["input"] } });
                window.MathJax.startup.defaultReady();
            },
        },
    })
    useEffect(() => {
        // setIpData({"Qtext": "\n \nSuppose \\(2 x - 5 y + 7 = 0\\), and \\(px + 6 y + 7 = 0\\) are parallel lines. Then find the value of \\(p\\).\n\n\n", "Steps": [{"id": "s1", "n_params": 2, "step_txt": "\n $$\\text{ The given equations of line are, }$$\n \\[(2) x - (5 ) y + (7 ) = 0\\]\n \\[y = \\frac{\\input[s1v1]{}}{5} x + \\frac{7}{5}\\]\n \\[y= mx +c \\]\n \\[ m = \\frac{2}{\\input[s1v2]{}}\\]\n \n "}, {"id": "s2", "n_params": 2, "step_txt": "\n $$\\text{ The given equations of line are, }$$\n \\[px + (6 ) y + (7 ) = 0\\]\n \\[y = \\frac{-p}{\\input[s2v1]{}} x - \\frac{7}{6}\\]\n \\[y= mx +c \\]\n \\[ m = \\frac{-p}{\\input[s2v2]{}}\\]\n \n "}, {"id": "s3", "n_params": 2, "step_txt": "\n \\[ m = \\frac{2}{5}\\]\n \\[ m = \\frac{-p}{6}\\]\n $$\\text{ line are parallel, so we can write , p we get}$$\n \\[\\frac{2}{5} = \\frac{-p}{6}\\]\n \\[p = \\frac{ -\\input[s3v1]{}}{\\input[s3v2]{}}\\]\n \\[p = \\frac{-12}{5}\\]\n \n "}]});
        //code for Mathjax to initialise
        window.MathJax = mathjaxConfig;
        // window.MathJax.Hub.Config({
        //     "HTML-CSS": { linebreaks: { automatic: true } },
        //     SVG: { linebreaks: { automatic: true } }
        //   });
        let script = document.createElement("script");
        script.defer = true;
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js";
        document.head.appendChild(script);

    }, [])


    useEffect(() => {
        typesetPromiseFunction();

    }, [mcqData])


  return (
    <McqTestingPageWrapper>
        <div className='list'>
            {
                mcqData.map((item,index)=>{
                    console.log(item);
                    return <div className='mcqCard'>
                        <div className='questionAndLevel'>
                            <p>Question : {index+1}</p>
                        <p>Level : {item?.level}</p>
                        </div>
                       
                        <p className='question'>Question : {item.question}</p>
                        
                        <div className='options'>
                            <span>A. {item.optionA}</span>
                            <span>B. {item.optionB}</span>
                            <span>C. {item.optionC}</span>
                            <span>D. {item.optionD}</span>
                        </div>

                        <div className='correctAnswer'>
                            <p>Correct Option :{item.correctOption}</p>
                        </div>

                        <div className='explanation'>
                            <p>Explanation : {item.explanation}</p>
                        </div>
                       
                    </div>
                })
        }
            
        </div>
    </McqTestingPageWrapper>
  )
}
