import React, { useEffect } from 'react'
import { ModalWrapper } from './modal.style'
import { Modal } from 'antd';
import closeicon from "assets/images/closeicon.svg";
import { useSelector } from 'react-redux';
import QuestionDetails from '../questionDetails/QuestionDetails';
import ShowImages from './ShowImages/ShowImages';
import EditUser from 'components/AdminActions/EditUserModal/EditUser';
import ChangePassword from 'components/AdminActions/ChangePasswordModal/ChangePassword';
import CreateNewUser from 'components/AdminActions/CreateNewUserModal/CreateNewUser';
import ShowQuestionDetails from 'components/AdminActions/showQuestionDetailsModal/ShowQuestionDetails';
import ShowFeedbackModal from 'components/Creator/ShowFeebackModal/ShowFeedbackModal';
import ReactCropImage from 'components/comman/ReactCrop/ReactCropImage';
import ShowMissionPdf from '../ShowMissionPdf/ShowMissionPdf';
import TextInputModal from '../TextInputModal/TextInputModal';
import AddNewConcept from '../AddNewConceptAdmin/AddNewConcept';
import ShowText from '../ShowText/ShowText';
import McqTestingpage from 'components/comman/mcqTestingPage/McqTestingPage';


export default function GenricModal(
  {
    title,
    isOpen,
    toggle,
    closable,
    width,
    onCancel,
    data,
    onSubmitClick,
    onClick2,
    onClick3
  }) {
    useEffect(()=>{
      console.log(data);
      console.log(title);
    },[data])
  return (
    <ModalWrapper>
        <Modal 
            title={title}
            open={isOpen}
            onCancel={onCancel?onCancel:() => {
              console.log("clicking it");
              toggle();
            }}
            centered
            closeIcon={closable?<img src={closeicon} alt="close" height="28" />:""}
            footer={null}
            closable={closable}
            width={width || 400}
            destroyOnClose={true}
        >
            {
            title=="Question Details"
            ?<QuestionDetails data={data} handleSubmitFilesClick={onSubmitClick} handleDownloadResources={onClick2}/>
            :
            title=="View Images"? <ShowImages attachments={data}/> 
            :
            title=="Edit User Details"?<EditUser data={data}/>:
            title=="Change Password"? <ChangePassword data={data} toggleModal={toggle}/>:
            title == "Create New User"?<CreateNewUser toggleModal={toggle} />:
            title =="Admin Question Details"? <ShowQuestionDetails data={data} onCancel={onCancel} />:
            title =="Verifier Feedback"?<ShowFeedbackModal data={data} />:
            title == "Crop Image"?<ReactCropImage src = {data} onOkClick ={onSubmitClick}/>:
            title=="Verify Mission Program"?<ShowMissionPdf />:
            title=="Add Text"?<TextInputModal data={data} onOkClick ={onSubmitClick} onCancel={onCancel} />:
            title =="Add New Concept"?<AddNewConcept data={data} onOkClick ={onSubmitClick} />:
            title=="Text"?<ShowText data={data} />:
            title=='Test Mcq Questions'?<McqTestingpage data={data}/>:
            <></>
            }
        </Modal>
    </ModalWrapper>
  )
}
